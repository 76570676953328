body {
    background-color: #f6feff;
}

.spinner {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #dbdcef;
    border-right-color: #ffffff;
    animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
    to {
        transform: rotate(1turn);
    }
}

.my-clip-path {
    /* clip-path: ellipse(50% 40% at 50% 0%); */
    background-color: #aaccaf;
    clip-path: ellipse(70% 50% at 50% 0%);
}

.clip-container {
    background-color: #aaccaf; /* Background color */
    clip-path: ellipse(70% 50% at 50% 0%); /* Adjusted clip path for better fit */
    padding: 2rem; /* Padding inside the clipping path */
    border-radius: 1rem; /* Optional: for rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: for some depth */
    display: flex; /* Ensure flex properties are respected */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    height: auto; /* Ensure container height adjusts to fit content */
}

.half-moon {
    width: 100%;
    height: 200px;
    background: #007bff; /* Change color as needed */
    clip-path: path("M 100,0 A 100,100 0 1,1 0,100 A 100,100 0 1,1 100,0 Z");
}

.half-ellipse2 {
    border-radius: 70% / 0 0 70% 70%;
}

.half-ellipse {
    background-color: #d2f8d0;
    /* background-color: #32557f; */
    width: 100%;
    height: 200px;
    padding-top: 3rem;
}

.half-ellipse-my-loan {
    background-color: #d2f8d0;
    /* background-color: #32557f; */
    width: 100%;
    height: 150px;
}

@media screen and (max-width: 648px) {
    .half-ellipse {
        /* background-color: #aaccaf; */
        background-color: #d2f8d0;
        /* background-color: #32557f; */
        width: 100%;
        height: 250px;
        padding-top: 3rem;
    }
}

.my-custom-height {
    --custom-width: 1000px;
    min-height: calc(900px - 200px);
}

.my-shadow {
    /* box-shadow: 8px 6px 7px -2px #88cfca; */
    /* box-shadow: 3px 4px 7px 2px #88cfca; */
    box-shadow: 3px 4px 7px 0px #88cfca;
}

/* Phone */

.react-phone-input-2 {
    height: fit-content;
    width: 100%;
    border-radius: 2px;
    padding: 10px;
    padding-left: 46px;
    background-color: #e5e7eb;
    margin-top: 16px;
}

.custom-scrollbar {
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 200px; /* Set a maximum height for the element */
}

/* WebKit styles for the custom scrollbar */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f0f0f0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Firefox styles */
.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
}

.nav-menu {
}

.cust-smoot-scroll {
    scroll-behavior: smooth;
}

/* Target the scrollbar in webkit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 6px; /* Adjust this to make it smaller */
    height: 6px; /* Optional: for a horizontal scrollbar */
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

/* Style the scrollbar track (the background part) */
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}


.slick-slider > div {
    padding: 10px 0 10px 0;

}


